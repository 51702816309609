import 'firebase/auth';

const config = {
  apiKey: "AIzaSyDQFNGGLbjsAk6pm1yNhYeBPKYoQnWsLe0",
    authDomain: "mhorsdal-59cbf.firebaseapp.com",
    databaseURL: "https://mhorsdal-59cbf.firebaseio.com",
    projectId: "mhorsdal-59cbf",
    storageBucket: "mhorsdal-59cbf.appspot.com",
    messagingSenderId: "967373753038",
    appId: "1:967373753038:web:1d5e9ecb32cddc6460de9f"
};

  export {
    config
  }