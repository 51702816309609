import * as React from 'react';
import firebase from "firebase";
import { Card, Button } from 'react-bootstrap';
import { useCollection } from 'react-firebase-hooks/firestore';

const Imagelist = () => {
    const [value, loading, error] = useCollection(firebase.firestore().collection('images'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        });

    const handleDescriptionChange = (id: string, description: string) => {
        const docref = value.docs.find(doc => doc.id === id);
        docref.ref.update({
            description: description
        })
            .then( () => {
                console.log("Document successfully updated!");
            })
            .catch( (error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
    }

    const handleImagesizeChange = (id: string, imagesize: string) => {
        const docref = value.docs.find(doc => doc.id === id);
        docref.ref.update({
            imagesize: imagesize
        })
            .then( () => {
                console.log("Document successfully updated!");
            })
            .catch( (error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
    }

    const handledeleteimg = (id:string) => {
        const docref = value.docs.find(doc => doc.id === id);
        const url = docref.get('path');
        docref.ref.delete().then(() => {
            console.log("Document successfully deleted!");
            firebase.storage().refFromURL(url).delete().then(
                () => {
                    console.log("Document successfully deleted!");
                }
            ).catch(
                (error) => {
                    console.error("Error updating document: ", error);
                }
            )
        });

    }

    return (
        <>
            {error && <strong>Error: {JSON.stringify(error)}</strong>}
            {loading && <span>Henter billeder...</span>}
            {value && (
                <span>
                    Collection:{' '}
                    {value.docs.map(doc => (

                        <React.Fragment key={doc.id}>
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={doc.get('path')} />
                                <Card.Body>
                                    <Card.Title><input onChange={(e) => handleDescriptionChange(doc.id, e.target.value)} type="text" value={doc.get('description')} /></Card.Title>
                                    <Card.Text>
                                        <input onChange={(e) => handleImagesizeChange(doc.id, e.target.value)} type="text" value={doc.get('imagesize')} />
                                    </Card.Text>
                                    <Button onClick={() => handledeleteimg(doc.id)} variant="danger">slet</Button>
                                </Card.Body>
                            </Card>
                        </React.Fragment>
                    ))}
                </span>
            )}

        </>
    )
}

export default Imagelist;