import React from 'react';

const Header = () => {
    return(
        <>
            <h1 className="center title">Marianne Horsdal</h1>
        </>
    );
}

export default Header;