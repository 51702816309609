import React from 'react';
import { Row, Col } from 'react-bootstrap';

const Contact = () => {
    return(
        <>
            <Row>
                <Col lg="2"></Col>
                <Col xs="12" sm="12" md="12" lg="3">
                <hr></hr>
                    <h3>Kontakt</h3>
                    <div>Marianne Horsdal</div>
                    <div>Nørrebystrand 27</div>
                    <div>5400 Bogense</div>
                    <div>+45 29254132</div>
                    <a href="mailto: mhorsdal@mail.dk"> email klik her! </a>
                </Col>
            </Row>
        </>
    )
}

export default Contact;