import React from 'react';
import logo from './logo.svg';
import './App.scss';
import * as firebase from "firebase/app";
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Home from './components/home';
import Admin from './components/admin';
import Login from './components/login';
import { Navigation } from './components/navigation';
import { FirebaseAuthProvider } from '@react-firebase/auth';
import { config } from './components/firebase/config';
import Gallery from './components/gallery';
import Header from './components/common/header';
import Contact from './components/common/contact';

const App: React.FC = () => {
  return (
    <FirebaseAuthProvider firebase={firebase} {...config}>
    <div className="App">
      <Router>
        
        <Navigation></Navigation><Header></Header>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/admin" component={Admin} />
          <Route path="/login" component={Login} />
          <Route path="/gallery" component={Gallery} />
        </Switch>
      </Router>
      <Contact></Contact>
    </div>
    </FirebaseAuthProvider>
  );
  }
export default App;
