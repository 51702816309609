import React from "react";
import ImageUploader from "./imageuploader";
import Imagelist from "./imagelist";

const Admin = () => {
    return (
        <div>
            admin page
            <ImageUploader></ImageUploader>
            <hr>
            </hr>
            <Imagelist></Imagelist>
        </div>
    )
}

export default Admin;