import * as React from 'react';
import { Row, Col } from 'react-bootstrap';



const Home = () => {
    return (
       <>
       <Row>
           <Col>
                <h2>Nordfynske Landskaber</h2>
           </Col>
       </Row>
       <Row>
           <Col>
                <p>Marianne Horsdal er optaget af lyset, farverne og landskabet. Maleri, fortrinsvis akryl på lærred. Derudover aquareller og pasteller.</p>
           </Col>
       </Row>
       <Row>
           <Col>
                
           
            
                <img className="homeimg" src="https://firebasestorage.googleapis.com/v0/b/mhorsdal-59cbf.appspot.com/o/uploads%2F1531753211027_gyldenstenstrand.jpg?alt=media&token=6fe12fe0-818f-45c8-852e-4f72baa91e0a"></img>
            </Col>
       </Row>
        </>
    );
};

export default Home;
