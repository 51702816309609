import * as React from 'react';
import { FirebaseAuthConsumer } from '@react-firebase/auth';
import firebase, { firestore, storage } from "firebase";
import { Col, Row } from 'react-bootstrap';

const ImageUploader = () => {
    const [progress, setProgress] = React.useState(0);
    const [filename, setfilename] = React.useState("");
    const [imageurl, setimageurl] = React.useState("");
    const [image, setimage] = React.useState(null);
    const [description, setDescription] = React.useState("");
    const [size, setSize] = React.useState("");
    const [dbmessage, setdbMessage] = React.useState("");

    const handleChange = (e: any) => {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            setimage(image);
            setfilename(image.name);
            setProgress(0);
        }
    };


    const handleUpload = () => {
        const uploadTask = firebase.storage().ref(`uploads/${filename}`).put(image!);
        uploadTask.on(
            "state_changed",
            snapshot => {
                // progress function ...
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress)
            },
            error => {
                // Error function ...
                console.log(error);
            },
            () => {
                // complete function ...
                firebase.storage()
                    .ref("uploads")
                    .child(filename)
                    .getDownloadURL()
                    .then(url => {
                        setimageurl(url);
                        
                        
                        firebase
                            .firestore()
                            .collection('images')
                            .add({
                                description: description,
                                imagesize: size,
                                path: url,
                                size: image.size
                            }).then((ref) => { console.log(ref); setdbMessage("upload done!!"); setTimeout(() => {
                                setdbMessage("");
                            }, 2000); });
                    });
            }
        );
    };


    return (
        <>
            <FirebaseAuthConsumer>
                {({ isSignedIn, user, providerId }) => {
                    if (!isSignedIn) {
                        return (
                            <>
                                Access denied!!!
                  
                                please log in.
              </>
                        )
                    }
                    return (
                        <>
                            <Row>
                                <Col>
                                    <h2 className="green-text">Tilføj billede</h2>
                                </Col>
                            </Row>

                            <br />
                            <br />
                            <Row>
                                <Col>
                                    <div className="imagedimensions">
                                        <label>Beskrivelse</label>
                                        <input type="text" onChange={e => setDescription(e.target.value)} />
                                        <label>Størrelse</label>
                                        <input type="text" onChange={e => setSize(e.target.value)} />
                                    </div>
                                </Col>
                            </Row>

                            <br />
                            <br />
                            <br />
                            <Row>
                                <Col>
                                    <div className="file-field input-field">
                                        <div className="btn">
                                            <input type="file" onChange={e => handleChange(e)} />
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <img
                                        src={imageurl || "https://via.placeholder.com/400x300"}
                                        alt="Uploaded Images"
                                        height="300"
                                        width="400"
                                    />
                                </Col>
                            </Row>
                            {description.length > 0 && size.length > 0 && image !== null &&
                            <button type="button" onClick={() => handleUpload()} className="btn-info btn"> 
                                Upload nyt billede
                            </button>
                            }
                            <br />
                            <progress value={progress} max="100" className="progress" />
                            {dbmessage}
                            <br />

                        </>
                    );
                }}
            </FirebaseAuthConsumer>
        </>
    );
};

export default ImageUploader;
