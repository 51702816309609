import React from "react";
import * as firebase from "firebase/app";



const Login = () => {
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");

    return (
        <>
        <div>
            login page
        </div>
       
        <div>
      <h1>Login</h1>
      <form onSubmit={e=> {e.preventDefault();firebase.auth().signInWithEmailAndPassword(email, password);}}>
        <input
          value={email}
          onChange={e => setEmail(e.target.value)}
          name="email"
          type="email"
          placeholder="email"
          onKeyDown={e=>e.key==='Enter'&&firebase.auth().signInWithEmailAndPassword(email, password)}
        />
        <input
          onChange={e => setPassword(e.target.value)}
          name="password"
          value={password}
          type="password"
          placeholder="password"
        />
        <hr />
        <button type="button" onClick={() => {firebase.auth().signInWithEmailAndPassword(email, password)}}>Login fra form</button>
      </form>
    </div>
</>
    )
}

export default Login;