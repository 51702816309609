import * as React from 'react';
import { Navbar, Nav } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { FirebaseAuthConsumer } from '@react-firebase/auth';
import * as firebase from "firebase/app";

export const Navigation = () => (
  <FirebaseAuthConsumer>
   {({ isSignedIn, user, providerId }) => {
      return (isSignedIn  ? <NavigationAuth /> : <NavigationNonAuth />);
    }}
  </FirebaseAuthConsumer>
);

const NavigationAuth = () => (
  <>
    <Navbar collapseOnSelect expand="xl" >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" >
      </Navbar.Toggle>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto row">
          <Nav.Link  eventKey="1" as={Link} to={'/Admin'}>Admin</Nav.Link>
           <Nav.Link eventKey="2" as={Link} to={'/'}>Hjem</Nav.Link>
           <Nav.Link eventKey="3" as={Link} to={'/Gallery'}>Galleri</Nav.Link>
           <button className="navlink" type="button" onClick={() => {firebase.auth().signOut()}}> Sign Out </button>
        </Nav>
      </Navbar.Collapse>
      
    </Navbar>

  </>
);

const NavigationNonAuth = () => (
  <>
    <Navbar collapseOnSelect expand="lg" >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" >
      </Navbar.Toggle>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
           <Nav.Link eventKey="1" as={Link} to={'/'}>Hjem</Nav.Link>
           <Nav.Link eventKey="2" as={Link} to={'/Login'}>Log ind</Nav.Link>
           <Nav.Link eventKey="3" as={Link} to={'/Gallery'}>Galleri</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </>
);