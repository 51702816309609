import React from "react";
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import firebase from "firebase";
import { useCollection } from 'react-firebase-hooks/firestore';
import { GalleryImage } from "../common/galleryimage";
import { Row, Col } from "react-bootstrap";

const Gallery = () => {

    const [value, loading, error] = useCollection(firebase.firestore().collection('images'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        });

    const getImages = () => {
        const images = Array<ReactImageGalleryItem>()
        if (!loading) {
            value.docs.map(doc => {
                images.push({ original: doc.get('path'), thumbnail: doc.get('path'), description: (doc.get('description') + ' ' + doc.get('imagesize')) })
            });
            return (images);
        }
    }

    return (
        <>
            <Row>
                <Col ></Col>
                <Col md='8'>
                    <ImageGallery items={getImages()} />
                </Col>
                <Col ></Col>
            </Row>

        </>
    )
}

export default Gallery;